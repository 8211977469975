import React from "react"
import Layout from "../layout/master"

const ProjectPage = () => (
  <Layout>
    {/*Begin Header*/}
    <section id="home" className="section section-home min-h-viewport header-home ">
      <div className="section-cover align-x-center parallax-anim">
        <div className="row no-gutters width-medium bg-part">
          <div className="d-none d-lg-block col-lg-6"></div>
          <div className="col-12 col-lg-6 bg-white bg-wrapper sr-zoomout-3">
            <div className="mask bg-black-9 opacity-2 d-lg-none"></div>
            <div className="bg-cover-img bg-img" data-image-src="img/samples/img-portrait5.jpg"></div>
          </div>
        </div>
      </div>
      <div className="section-content align-x-center align-y-center">
        <div className="width-medium">
          <div className="row no-gutters">
            <div className="col-12 col-lg-6">
              <div className="home-left content-text">
                <h4 className="h-subtitle sr-up-2">UX Design / Coding</h4>
                <h2 className="h-title font-title sr-up-3">Lamp
                  <br/> <span className="text-stroke">Online store</span>
                </h2>
                <div className="btns-group sr-up-4">
                  <a className="btn btn-line-a px-0 py-0" href="#">
                    <span className="text">Visit Website</span>
                    <span className="icon icon-menu icon-arrow-a icon-anim">
                      <span className="arrow-down"></span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*About project*/}
    <section id="about-us" className="section section-page section-margin bg-level-1">
      <div className="section-cover"></div>
      <div className="section-header align-x-center">
        <div className="h-content width-medium">
          <h2 className="h-title font-title sr-up-1">
            About the item
          </h2>
        </div>
      </div>

      <div className="section-content align-x-center">
        <div className="c-wrapper width-medium">
          <div className="row no-gutters">
            <div className="col col-12">
              <div className="content-text sr-up-2">
                <article className="article-a">
                  <p>
                    This is a sample article text describing something. Lorem ipsum dolor sit amet, consectetuer
                    adipiscing elit. Maecenas porttitor congue
                    massa.
                    Fusce
                    posuere,
                    magna sed pulvinar ultricies, purus lectus malesuada libero. Nunc viverra imperdiet
                    enim.
                    Fusce
                    est.
                  </p>
                  <h3>Introduction</h3>
                  <p>Vivamus
                    a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
                    turpis
                    egestas.</p>
                  <ul>
                    <li>Ut enim ad minima veniam</li>
                    <li>Quis nostrum exercitationem</li>
                    <li>Ullam corporis suscipit laboriosam</li>
                  </ul>
                  <p>
                    Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque
                    laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore
                    veritatis et quasi architecto beatae vitae dicta sunt, explicabo.
                  </p>
                  <blockquote>
                    <p>
                      <i>Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.</i>
                    </p>
                  </blockquote>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-footer"/>
    </section>
  </Layout>
)

export default ProjectPage
